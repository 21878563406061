<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{ $t("message.graphic") }}</p>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <div class="app-form__group mb-4">
              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                {{ $t("message.nameGiven") }}
              </span>
              <el-form-item prop="name">
                <el-input
                  size="medium"
                  v-model="form.name"
                  autocomplete="off"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
            
            <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{ $t("message.branch") }} </span>
                  <el-form-item prop="branch_id">
                    <select-branch :id="form.branch_id" :value="form.branch_id" v-model="form.branch_id"> </select-branch>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{ $t("message.graphicType") }} </span>
                  <el-form-item prop="graphic_type_id">
                    <select-graphicType
                      :id="form.graphic_type_id"
                      :size="'medium'"
                      :placeholder="columns.graphic_type_id.title"
                      v-model="form.graphic_type_id"
                    ></select-graphicType>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <div class="input--label mb-2">
                    {{ $t("message.is_free") }}
                  </div>
                  <el-switch v-model="form.is_free" value="1"> </el-switch>
                </div>
              </el-col>
            </el-row>
            <div v-if="!form.is_free">
              <div  class="crm-scha"
                v-for="(day, index) in schedules"
                :key="index + 'day'" >
                <div class="titles">
                  {{ day.day }}
                </div>

                <div class="if-v" v-if="day.is_working_day">
                  <span v-if="day.is_working_day" class="otdo">
                    {{ $t("message.daily") }}
                  </span>
                  <div class="el-time_ruls w100s">
                    <el-time-select
                      required
                      :placeholder="$t('message.from')"
                      v-model="day.clock_in"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-time-select>
                    <span class="rulus" v-if="day.clock_in == null">
                      {{ $t("message.language_graphic_type") }}
                    </span>
                  </div>

                  <div class="el-time_ruls w100s">
                    <el-time-select
                      :placeholder="$t('message.before')"
                      v-model="day.clock_out"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '24:00',
                        minTime: day.clock_in,
                      }"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-time-select>
                    <span class="rulus" v-if="day.clock_out == null">
                      {{ $t("message.language_graphic_type") }}
                    </span>
                  </div>

                  <span v-if="day.is_working_day" class="otdo">
                    {{ $t("message.Break") }}
                  </span>

                  <div class="el-time_ruls w100s">
                    <el-time-select
                      :placeholder="$t('message.from')"
                      v-model="day.break_start_time"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '24:00',
                      }"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-time-select>
                    <span class="rulus" v-if="day.break_start_time == null">
                      {{ $t("message.language_graphic_type") }}
                    </span>
                  </div>

                  <div class="el-time_ruls w100s">
                    <el-time-select
                      :placeholder="$t('message.before')"
                      v-model="day.break_end_time"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '24:00',
                        minTime: day.break_start_time,
                      }"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-time-select>
                    <span class="rulus" v-if="day.break_end_time == null">
                      {{ $t("message.language_graphic_type") }}
                    </span>
                  </div>
                </div>

                <el-switch
                  class="ml-2"
                  v-model="day.is_working_day"
                  @change="empty(day)"
                >
                </el-switch>
              </div>
            </div>
            <div v-else>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.monthly_limit_work_hour") }}
                    </span>
                    <el-form-item prop="monthly_limit_work_hour">
                      <el-input
                        size="medium"
                        v-model="form.monthly_limit_work_hour"
                        autocomplete="off"
                        :type="'number'"
                        :class="mode ? 'input__day' : 'input__night'"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.monthly_limit_work_day") }}
                    </span>
                    <el-form-item prop="monthly_limit_work_day">
                      <el-input
                        size="medium"
                        :type="'number'"
                        v-model="form.monthly_limit_work_day"
                        autocomplete="off"
                        :class="mode ? 'input__day' : 'input__night'"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectGraphicType from "@/components/filters/inventory/select-graphicType";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  name: "GraphicController",
  components: {
    selectGraphicType
  },
  data() {
    return {
      form:{
        is_free:false,
        monthly_limit_work_hour:0,
        monthly_limit_work_day:0
      },
      schedules: [
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 1,
          day: "Понедельник",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 2,
          day: "Вторник",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 3,
          day: "Среда",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 4,
          day: "Четверг",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 5,
          day: "Пятница",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 6,
          day: "Суббота",
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 0,
          day: "Воскресенье",
        },
      ],
      
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "graphic/rules",
      model: "graphic/model",
      columns: "graphic/columns",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "graphic/store",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid && this.validateSchedules(this.schedules)) {
          this.loadingButton = true;
          this.form.schedules = this.schedules;
          this.form.is_free = this.form.is_free? this.form.is_free:false;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) this.close();
            })
            .catch((err) => {
              console.log("error is happening");
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    validateSchedules(schedules) {
      for (let key in schedules) {
        if (schedules[key].is_working_day) {
          if (
            schedules[key].clock_in == null ||
            schedules[key].clock_out == null ||
            schedules[key].break_start_time == null ||
            schedules[key].break_end_time == null
          ) {
            return false;
          }
        }
      }
      return true;
    },
    empty(day) {
      if (!day.is_working_day) {
        (day.clock_in = null),
          (day.clock_out = null),
          (day.break_start_time = null),
          (day.break_end_time = null);
      }
    },
  },
};
</script>
<style  lang="scss">
.otdo {
  min-width: 30px;
  margin-left: 20px;

  display: inline-block;
}
.crm-scha {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #00000021;
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative;
}
.crm-scha .titles {
  width: 120px;
}
.crm-scha .el-date-editor {
  margin-left: 10px;
}
.rulus {
  bottom: 0px;
  font-size: 10px;
  color: red;
  padding-left: 10px;
  display: block;
}
.el-time_ruls.w100s {
  position: relative;
  flex: 1;
  margin-right: 6px;
}
.el-time_ruls.w100s .el-input {
  width: 100%;
}
.if-v {
  flex: 1;
  display: flex;
  align-items: center;
}
.table__mynight .crm-scha{
  border-color: rgb(120, 119, 119);
}
.performance__night{
  .el-switch__core{
    background: #323248;
    border-color:  rgb(187, 187, 187);
    &::after{
      background: rgb(238, 238, 238);
    }
  }
}
</style>
