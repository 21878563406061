<template>
  <div class="p-pegas">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
          <div class="card-table-body ifon-add-title-block">
              <el-row :gutter="20">
                  <el-col :span="12">
                      <div class="caz-blocks-sarcho-title">
                          <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                              {{ $t("message.graphic") }}
                          </div>
                          <div class="block-sarche">
                              <div class="header__search">
                                  <crm-input
                                      :size="'small'"
                                      :className="'w100'"
                                      :class="mode ? 'input__day' : 'input__night'"
                                      v-model="filterForm.search"
                                      :icon="'el-icon-search'"
                                  ></crm-input>
                              </div>
                          </div>
                      </div>
                  </el-col>

                  <el-col :span="12" class="flex-style text-right">
                      <crm-create-and-column-settings
                          :permission="$options.name"
                          :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                          @c-create="drawerCreate = true"
                          :columns="columns"
                          @c-change="updateColumn"
                          :v_can_create="'GraphicController@store'"
                      >
                      </crm-create-and-column-settings>
                  </el-col>
              </el-row>
          </div>
          <!-- end ifon-add-title-block -->

          <div class="card-table-header table-crm-smart">
              <table
                  class="table-my-code table-bordered"
                  :class="mode ? 'table__myday' : 'table__mynight'"
                  v-loading="loadingData"
              >
                  <thead>
                      <tr>
                          <th class="w50p" v-if="columns.id.show">
                              {{ columns.id.title }}
                          </th>

                          <th v-if="columns.name.show">
                              {{ columns.name.title }}
                          </th>
                          <th v-if="columns.graphic_type_id.show">
                              {{ columns.graphic_type_id.title }}
                          </th>
                          <th v-if="columns.branch.show">
                            {{ columns.branch.title }}
                          </th>
                          <th v-if="columns.total_working_day.show">
                              {{ columns.total_working_day.title }}
                          </th>

                          <th v-if="columns.created_at.show">
                              {{ columns.created_at.title }}
                          </th>

                          <th v-if="columns.updated_at.show">
                              {{ columns.updated_at.title }}
                          </th>

                          <th v-if="columns.settings.show">
                              {{ columns.settings.title }}
                          </th>
                      </tr>

                      <tr class="filter_sorche">
                          <th v-if="columns.id.show">
                              <el-input
                                  clearable
                                  size="mini"
                                  v-model="filterForm.id"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  :placeholder="columns.id.title"
                                  class="id_input"
                              ></el-input>
                          </th>

                          <th v-if="columns.name.show">
                              <crm-input
                                  :placeholder="columns.name.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.name"
                              ></crm-input>
                          </th>

                          <th v-if="columns.graphic_type_id.show">
                              <select-graphicType
                                  :placeholder="columns.graphic_type_id.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.graphic_type_id"
                              ></select-graphicType>
                          </th>
                          <th v-if="columns.branch.show">
                            <select-branch
                                :placeholder="columns.branch.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.branch_id"
                            ></select-branch>
                          </th>
                          <th v-if="columns.total_working_day.show">
                              <crm-input
                                  :placeholder="
                                      columns.total_working_day.title
                                  "
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.total_working_day"
                              ></crm-input>
                          </th>

                          <th v-if="columns.created_at.show">
                              <crm-date-picker
                                  :placeholder="columns.created_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.created_at"
                              ></crm-date-picker>
                          </th>

                          <th v-if="columns.updated_at.show">
                              <crm-date-picker
                                  :placeholder="columns.updated_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.updated_at"
                              ></crm-date-picker>
                          </th>

                          <th
                              class="settinW"
                              v-if="columns.settings.show"
                          ></th>
                      </tr>
                  </thead>

                  <transition-group name="flip-list" tag="tbody">
                      <tr
                          v-for="graphic in list"
                          :key="graphic.id"
                          class="cursor-pointer"
                      >
                          <td v-if="columns.id.show">{{ graphic.id }}</td>

                          <td v-if="columns.name.show">
                              {{ graphic.name }}
                          </td>

                          <td v-if="columns.graphic_type_id.show">
                              {{
                                  graphic.graphic_type
                                      ? graphic.graphic_type.name
                                      : null
                              }}
                          </td>
                          <td v-if="columns.branch.show">
                            {{
                                graphic.branch
                                    ? graphic.branch.name
                                    : ''
                            }}
                          </td>

                          <td v-if="columns.total_working_day.show">
                              {{ graphic.total_working_day }}
                          </td>

                          <td v-if="columns.created_at.show">
                              {{ graphic.created_at }}
                          </td>

                          <td v-if="columns.updated_at.show">
                              {{ graphic.updated_at }}
                          </td>

                          <td
                              v-if="columns.settings.show"
                              class="settings-td"
                          >
                              <crm-settings
                                  :name="$options.name"
                                  :model="graphic"
                                  :actions="actions"
                                  :permissionShow="'GraphicController@update'"
                                  :permissionDestroy="'GraphicController@destroy'"
                                  @edit="edit"
                                  @delete="destroy"
                              ></crm-settings>
                          </td>
                      </tr>
                  </transition-group>
              </table>
              <div class="my___pagination">
                  <crm-pagination
                      @c-change="updatePagination"
                      :class="mode ? 'pagination__day' : 'pagination__night'"
                      :pagination="pagination"
                  ></crm-pagination>
              </div>
          </div>

          <div class="app-modal app-modal__full">
              <el-drawer
                  :with-header="false"
                  :visible.sync="drawerCreate"
                  size="90%"
                  ref="drawerCreate"
                  @opened="drawerOpened('drawerCreateChild')"
                  @closed="drawerClosed('drawerCreateChild')"
              >
                  <crm-create
                      ref="drawerCreateChild"
                      drawer="drawerCreate"
                  ></crm-create>
              </el-drawer>

              <el-drawer
                  :with-header="false"
                  :visible.sync="drawerUpdate"
                  size="90%"
                  ref="drawerUpdate"
                  @opened="drawerOpened('drawerUpdateChild')"
                  @closed="drawerClosed('drawerUpdateChild')"
              >
                  <crm-update
                      :selectedItem="selectedItem"
                      ref="drawerUpdateChild"
                      drawer="drawerUpdate"
                  ></crm-update>
              </el-drawer>
          </div>
      </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import selectGraphicType from "@/components/filters/inventory/select-graphicType";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "GraphicController",
  mixins: [list],
  components: {
      CrmCreate,
      CrmUpdate,
      selectGraphicType,
  },

  data() {
      return {};
  },
  computed: {
      ...mapGetters({
          list: "graphic/list",
          columns: "graphic/columns",
          pagination: "graphic/pagination",
          statues: "graphic/statues",
          filter: "graphic/filter",
          sort: "graphic/sort",
          mode: "MODE"
      }),
      actions: function () {
          return ["edit", "delete"];
      },
  },
  methods: {
      ...mapActions({
          updateList: "graphic/index",
          setPagination: "graphic/setPagination",
          updateSort: "graphic/updateSort",
          updateFilter: "graphic/updateFilter",
          updateColumn: "graphic/updateColumn",
          updatePagination: "graphic/updatePagination",
          show: "graphic/show",
          empty: "graphic/empty",
          delete: "graphic/destroy",
          refreshData: "graphic/refreshData",
      }),
  },
};
</script>
